import React from "react";

// import About from "../components/About";
import BackToTop from "../components/BackToTop";
// import Blog from "../components/Blog";
// import Contact from "../components/Contact";
import Pricing from "../components/Pricing";
// import Footer from "../Layout/Footer";
import FooterAlt from "../Layout/FooterAlt";
// import Portfolio from "../components/Portfolio";
import Features from "../components/Features";
// import Team from "../components/Team";

const Layout = (props) => {
  return (
    <React.Fragment>
      {props.children}
      <Features />
      {/* <About /> */}
      <Pricing />
      {/* <Contact /> */}
      {/* <Footer /> */}
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};

export default Layout;
