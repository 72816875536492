import React, { useState } from "react";
import ModalVideo from "react-modal-video";
// import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Spinner from "../UI/Spinner";

import formBuilderImg from "../assets/images/builder.svg";
import pdfFile from '../assets/pdfs/freeform_flyer.pdf';
import awsMarketplaceLogo from '../assets/images/aws_marketplace_logo_cropped.png'; // Adjust the path to your image


const openFlyer = () => {
    window.open(pdfFile, '_blank');
}
const scrollToContact = () => {
    document.getElementById('pricing').scrollIntoView({ behavior: 'smooth' });
}


const HeaderSection = (props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <React.Fragment>
      {/* <!-- START HOME --> */}
      <section className="bg-home" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="align-items-center">

            {props.mode === 'loading' 
            ? <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center',width: '100%'}}><Spinner /></div>
            :
            <Col lg={6}>
              <div className="home-content me-lg-5">
                {/* <h6 className="sub-title mb-3 text-white">Zoric Template</h6> */}
                <h1 className="mb-4 text-white">
                {/* {" "} <span className="text-primary">Citra</span> -  */}
                {props.title}
                </h1>
                <p className="text-white-50 fs-17">
                {props.subtitle}
                </p>
                    
                {props.mode === 'home' &&
                    <div className="mt-4">
                    <button onClick={scrollToContact} className="btn btn-primary mt-2">
                        Get Started
                    </button>{" "}
                    <button onClick={openFlyer} className="btn btn-outline-white mt-2 ms-md-1">
                        Learn More
                    </button>
                    <button className="btn btn-aws-marketplace mt-2 ms-2">
                        <a href='https://aws.amazon.com/marketplace/pp/prodview-5lc7nq6e7nsgk' target="_blank" rel="noopener noreferrer">
                        <img src={awsMarketplaceLogo} alt="" style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '90%',
                            height: 'auto',
                            objectFit: 'cover',
                        }}/>
                        </a>
                        AWS Market
                    </button>                    
                    {/* <Link to="/#" className="btn btn-primary mt-2">
                        Get Started
                    </Link>{" "} */}
                    {/* <NavLink href="#features" className="btn btn-outline-white mt-2 ms-md-1">
                        Learn More
                    </NavLink> */}

                    </div>
                }


              </div>
            </Col>
            }
            <Col lg={6}>
              <div className="home-dashboard mt-4 mt-lg-0">
                <img src={formBuilderImg} alt="" className="img-fluid" />

                {/* <div className="play-btn">
                <Link to="#" onClick={() => setOpen(true)} className="image-popup">
                    <i className="mdi mdi-motion-play"></i>
                </Link>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <!-- END HOME --> */}
      {/* <!-- START SHAPE --> */}
      <div className="position-relative">
        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1440"
            height="150"
            preserveAspectRatio="none"
            viewBox="0 0 1440 150"
          >
            <g mask='url("#SvgjsMask1022")' fill="none">
              <path
                d="M 0,58 C 144,73 432,131.8 720,133 C 1008,134.2 1296,77.8 1440,64L1440 250L0 250z"
                fill="rgba(255, 255, 255, 1)"
              ></path>
            </g>
            <defs>
              <mask id="SvgjsMask1022">
                <rect width="1440" height="250" fill="#ffffff"></rect>
              </mask>
            </defs>
          </svg>
        </div>
      </div>
      {/* <!-- END SHAPE --> */}
      <ModalVideo
        channel="vimeo"
        videoId="287684225"
        autoplay
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default HeaderSection;
