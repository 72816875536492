import React from "react";
import { Col, Container, Row } from "reactstrap";

import img01 from "../assets/images/feature/img-01.png";
import img02 from "../assets/images/feature/img-02.png";
import img03 from "../assets/images/feature/img-03.png";
// import img04 from "../assets/images/feature/img-04.png";
// import img05 from "../assets/images/feature/img-05.png";
// import img06 from "../assets/images/feature/img-06.png";

const FeaturesCard = (props) => {
  return (
    <>
      <Col lg={4} md={6}>
        <div
          className={
            props.isShadow
              ? "service-box text-center mt-4 box-shadow"
              : "service-box text-center mt-4"
          }
        >
          <img src={props.imgsrc} alt="" className="img-fluid" />
          <h5 className="fs-18 mt-4">{props.title}</h5>
          <div className="lighlight-border mt-3"></div>
          <p className="text-muted mt-3">{props.caption}</p>
        </div>
      </Col>
      {/* <!--end col--> */}
    </>
  );
}

// Features data

const FeaturesData = [
  {
    imgsrc: img01,
    title: "Form Builder",
    caption:
      "Utilise a powerful drag-and-drop interface to create forms quickly. Customise forms with a variety of field types including text, dropdowns, checkboxes, and more.",
  },
  {
    imgsrc: img02,
    title: "Reusable Components",
    caption:
      "Save time and maintain consistency by reusing sections and fields across different forms.",
    isShadow: true,
  },
  {
    imgsrc: img03,
    title: "Adaptive Forms",
    caption:
      "Forms adapt to user input, ensuring a relevant and concise experience for end-users.",
  },
];

const Features = () => {
  return (
    <>
      <section className="section" id="features">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}> 
              <div className="header-title text-center">
                {/* <p className="text-uppercase text-muted mb-2">
                  Features We Provide
                </p> */}
                <h3>Features</h3>
                <div className="title-border mt-3"></div>
                <p className="text-muted mt-3">
                Discover the powerful capabilities of our freeform adaptive form builder. Designed to simplify your workflow, it offers a seamless and customizable experience for creating dynamic forms tailored to your specific needs.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {/* Features Component Start */}

            {FeaturesData.map((value, idx) => (
              <FeaturesCard
                key={idx}
                imgsrc={value.imgsrc}
                title={value.title}
                caption={value.caption}
                isShadow={value.isShadow}
              />
            ))}

            {/* Features Component End */}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Features;
