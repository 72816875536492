import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const PricingData = [
  {
    title: "Free Version",
    price: "Free",
    isChildItem: [
      { id: 1, subItem: "Up to 5 forms" },
      { id: 2, subItem: "100 submissions / month" },
      { id: 3, subItem: "Basic unsecured API endpoints" },
    ],
    blurb: "Perfect for small projects and personal use, our Free version allows new users to explore the capabilities of FreeForm without any initial investment.",
    submitText: "Try for free",
    link: "https://build.freeform.citras.io/signuptype"
  },
  {
    title: "Paid Basic Plan",
    price: "$99 / month",
    isSale: true,
    isChildItem: [
      { id: 1, subItem: "Up to 20 forms" },
      { id: 2, subItem: "10,000 submissions / month" },
      { id: 3, subItem: "Multi-user access, custom dropdowns" },
      { id: 3, subItem: "Secured SQS API Integration" },
    ],
    blurb: "Designed for growing businesses, the Paid Basic plan introduces advanced features such as multi-user access and increased submission limits, providing more power and flexibility",
    submitText: "Subscribe",
    link: "https://build.freeform.citras.io/signuptype"
  },
  {
    title: "Custom Installation",
    price: "Custom Quote",
    isChildItem: [
      { id: 1, subItem: "Unlimited forms" },
      { id: 2, subItem: "Unlimited submissions" },
      { id: 3, subItem: "Consultancy for setup and configuration, tailored features to fit business needs" },
    ],
    blurb: "Our premium offering includes full customization and consultancy services to ensure FreeForm integrates seamlessly into your business infrastructure, with scalability to meet enterprise demands.",
    submitText: "Get in touch",
    link: '/contact'
  },
];

const Pricing = () => {
  return (
    <React.Fragment>
      <section className="section bg-light" id="pricing">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center mb-4">
                <p className="text-uppercase text-muted mb-2">Get Started</p>
                <h3>Choose Your Plan</h3>
                <div className="title-border mt-3"></div>
                <p className="title-desc text-muted mt-3">
                From startups needing simple forms to large corporations requiring complex form structures and integrations, FreeForm is designed to scale with your business. Enhance your ability to collect data and insights with minimal setup and maintenance.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            {/* Pricing Component Start */}

            {PricingData.map((items, key) => (
              <Col key={key} lg={4} md={6}>
                <div className="pricing-box bg-white box-shadow text-center p-5 mt-5 rounded">
                  {items.isSale && (
                    <div className="pricing-label">
                      <h5 className="text-white fs-16">Sale</h5>
                    </div>
                  )}

                  <p className="price-title mb-4 pb-3">{items.title}</p>
                  <h1 className="mb-0 price">{items.price}</h1>
                  <div className="pricing-features mt-4 pt-4">
                    {items.isChildItem.map((item, key) => (
                      <p key={key}>{item.subItem}</p>
                    ))}
                  </div>
                  <p className="text-muted pt-3">{items.blurb}</p>
                  <div className="mt-5">
                    <Link to={items.link} className="btn btn-primary w-100">
                      {items.submitText}
                    </Link>
                  </div>
                </div>
                {/* <!--end pricing-box--> */}
              </Col>
            ))}
            {/* Pricing Component End */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Pricing;
