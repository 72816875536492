import React from "react";
import NavBar from "../../Layout/Navbar";
// import Section from "./Section";
import HeaderSection from "../../components/HeaderSection";

const Layout1 = () => {
  return (
    <React.Fragment>
      <NavBar isDark={false} />
      {/* import Section */}
      {/* <Section /> */}
      <HeaderSection 
            title='Transform the way you gather information'
            subtitle="Citra Solutions’ FreeForm is a dynamic and versatile SaaS product designed to
streamline the process of form creation and management. "
            mode='home'
        />      
    </React.Fragment>
  );
};

export default Layout1;
